<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="centre-content">
        <div class="video-nav">
          <div v-if="isSearch != true">

            <el-tabs v-model="cateId" @tab-click="tabChange">
              <el-tab-pane :label="item.cateName" :name="item.cateId.toString()" v-for="(item,i) in navList" :key="i"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-if="videoList.length != 0">
          <div class="video-list">
            <div class="video-item" v-for="videoItem in videoList" @click="toDetail(videoItem.vid)" :key="videoItem.id">
              <div class="video-cover">
                <div class="cover-img">
                  <img :src="videoItem.coverImg" alt="" />
                </div>
                <div class="cover-info flex-box">
                  <div class="flex-box">
                    <p class="learnNum-img">
                      <img src="../assets/home/learn-num.png" alt="" />
                    </p>
                    <span>{{ videoItem.viewNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="shareNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ videoItem.favNum }}</span>
                  </div>
                </div>
              </div>
              <div class="video-info">
                <p class="video-title">{{ videoItem.title }}</p>
                <p class="video-introduce">讲师：{{ videoItem.lecturer }}</p>
                <p class="videoHover">
                  <img src="../assets/home/course-hover-icon.png" alt="" />
                </p>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-box">
            <p class="homePage hoverFinger" @click="currentChange(1)">首页</p>
            <el-pagination :page-size="perPage" background layout="prev, pager, next" :total="total" pager-count:7
              @current-change="currentChange" :current-page="currentPage">
            </el-pagination>
            <p class="endPage hoverFinger" @click="currentChange(Math.ceil(total / perPage))">
              尾页
            </p>
          </div>
        </div>
        <!-- 暂无内容 -->
        <div class="notBox" v-else>
          <p class="no-content">
            <img src="../assets/home/no-content.png" alt="" />
          </p>
          <p class="no-conent-text">暂无资源，平台正在努力添加资源~</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      navList: [],
      tabActive: 0,
      cateId: 0,
      videoList: [],
      currentPage: 1, // 当前页码
      total: 0, //列表总数量
      perPage: 8, //每页请求条数
      isSearch: false,
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    this.getSmallvideoCate();
    this.getSmallvideoList();
  },
  watch: {
    "$store.state.searchInfo"() {
      this.currentPage = 1;
      this.getSmallvideoList();
    },
  },
  //事件方法
  methods: {
    currentChange(val) {
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getSmallvideoList();
    },
    tabChange(cateId) {
      this.currentPage = 1;
      this.cateId = cateId.name;
      this.tabActive = cateId.name;
      this.getSmallvideoList();
    },
    toDetail(vid) {
      let pathInfo = this.$router.resolve({
        path: `/videoDetail/${vid}`,
      });
      window.open(pathInfo.href, "_blank");
    },
    getSmallvideoCate() {
      //课程分类
      let data = {};
      request({
        url: "/smallvideo/cate",
        method: "post",
        data,
      }).then((res) => {
        this.navList = res.data;
        this.navList.unshift({cateId:0,cateName:'全部'})
      });
    },
    getSmallvideoList() {
      if (
        this.searchType != undefined &&
        this.keyWord != undefined &&
        this.searchType == "weike"
      ) {
        this.searchList();
        return;
      }
      //课程列表
      let data = {
        cateId: this.cateId,
        pageNum: this.currentPage,
        perPage: this.perPage,
      };
      request({
        url: "/smallvideo/lists",
        method: "post",
        data,
      }).then((res) => {
        this.videoList = res.data.list;
        this.total = res.data.count;
      });
    },
    searchList() {
      let data = {
        searchType: this.searchType,
        keyWord: this.keyWord,
        perPage: this.perPage,
        pageNum: this.currentPage,
      };
      request({
        url: "/search",
        method: "post",
        data,
      }).then((res) => {
        this.isSearch = true;
        this.videoList = res.data.list;
        this.total = res.data.count;
      });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {
    searchType: function () {
      return this.$store.state.searchInfo.searchType;
    },
    keyWord: function () {
      return this.$store.state.searchInfo.keyWord;
    },
  },
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
.video-nav {
  padding: 30px 0px;

  .nav-list {
    display: flex;
    // justify-content: center;
    // height: 40px;
    flex-wrap: wrap;

    .nav-item {
      margin-right: 60px;
      font-size: 16px;
      color: #606266;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .nav-item-active {
      margin-right: 60px;
      margin-bottom: 10px;
      font-size: 16px;
      color: #4676f8;
      height: 26px;
      border-bottom: 4px solid #4676f8;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.video-list {
  display: flex;
  flex-wrap: wrap;

  .video-item {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    width: 336px;
    height: 275px;
    background: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
    border-radius: 8px;
    margin-right: 32px;
    margin-bottom: 40px;
    cursor: pointer;

    .video-cover {
      .cover-img>img {
        width: 336px;
        height: 190px;
        border-radius: 8px;
        background-color: #6f7174;
        display: block;
      }

      position: relative;
    }

    .cover-info {
      width: 303px;
      background: linear-gradient(180deg,
          rgba(54, 54, 56, 0) 0%,
          rgba(54, 54, 56, 1.5) 100%);
      border-radius: 0px 0px 8px 8px;
      position: absolute;
      bottom: 0px;
      display: flex;
      padding: 17px 16px 7px;
      font-size: 12px;
      color: #ffffff;
    }

    .video-info {
      padding: 16px;
      position: relative;

      .video-title {
        font-size: 16px;
        color: #3b3d41;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .video-title :hover {
        color: #4676f8;
      }

      .video-introduce {
        font-size: 12px;
        color: #909399;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .videoHover {
        position: absolute;
        right: 0;
        bottom: -8px;
        display: none;
      }

      .videoHover>img {
        width: 49px;
        height: 48px;
      }
    }
  }

  .video-item:hover .videoHover {
    display: inline-block;
    animation: mymove 2s infinite;
    animation-iteration-count: 1;
    /*设置动画播放次数*/
  }

  @keyframes mymove {
    from {
      opacity: 0;
    }

    to {
      opacity: 100;
    }
  }

  .video-item:nth-child(4n) {
    margin-right: 0px;
  }

  .video-item:hover {
    // box-shadow: 0px 6px 15px 0px rgba(0, 83, 249, 0.15);
    // transform: translateY(-.60rem); //向上浮动了8个像素
    // animation: mymove 2s infinite;
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
</style>
