import { render, staticRenderFns } from "./videoDetail.vue?vue&type=template&id=9918ec90&scoped=true&"
import script from "./videoDetail.vue?vue&type=script&lang=js&"
export * from "./videoDetail.vue?vue&type=script&lang=js&"
import style0 from "./videoDetail.vue?vue&type=style&index=0&id=9918ec90&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9918ec90",
  null
  
)

export default component.exports